<script>
  import Carousel from "svelte-carousel/src/components/Carousel/Carousel.svelte";
  import imagesList from "../../constants/imageList";
</script>

<div class="hidden lg:block max-w-full lg:max-w-sm">
  <Carousel dots={true} arrows={true}>
    {#each imagesList as src}
      <div class="img-slider-container lg:max-w-sm">
        <img {src} alt="monk-img" />
      </div>
    {/each}
  </Carousel>
</div>

<div class="block lg:hidden w-screen">
  <Carousel
    dots={false}
    arrows={false}
    particlesToShow={4}
    particlesToScroll={2}
  >
    {#each imagesList as src}
      <div class="img-slider-container">
        <img {src} alt="monk-img" />
      </div>
    {/each}
  </Carousel>
</div>
