const setWalletConnect = (isConnected) => {
  localStorage.setItem("isConnected", isConnected);
};
const isWalletConnected = () => {
  try {
    if (window.ethereum) {
      if (window.ethereum.isConnected()) {
        return (
          localStorage.getItem("isConnected") === "true" &&
          window.ethereum.isConnected()
        );
      } else {
        setWalletConnect(false);
        return false;
      }
    } else {
      setWalletConnect(false);
      return false;
    }
  } catch (e) {
    return false;
  }
};

export { setWalletConnect, isWalletConnected };
