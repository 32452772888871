<script>
  import { createEventDispatcher } from "svelte";
  import { scale } from "svelte/transition";
  import Button from "../Button.svelte";
  const dispatch = createEventDispatcher();

  export let ready;
  export let isConnected;
</script>

<div class="hidden flex-row items-center m-8 mx-14 lg:flex">
  <div class="w-full space-x-12 flex flex-row items-center justify-center">
    <a
      href="https://thehighmonks.com/roadmap-2/"
      target="_blank"
      class="text-base font-medium text-white hover:text-indigo-50"
    >
      Roadmap
    </a>
    <a
      href="https://discord.gg/RxgRqrVzzH"
      target="_blank"
      class="text-base font-medium text-white hover:text-indigo-50"
    >
      Community
    </a>
    {#if ready}
      <div
        in:scale={{ duration: 300 }}
        out:scale={{ duration: 300 }}
        class="inline-block"
      >
        {#if isConnected}
          <Button
            classNames="py-2 text-sm px-6"
            isDefault={true}
            isRounded={true}
            on:click={() => dispatch("walletBtnHandler", false)}
            >Disconnect Wallet</Button
          >
        {:else}
          <Button
            classNames="py-2 text-sm px-6"
            isDefault={true}
            isRounded={true}
            on:click={() => dispatch("walletBtnHandler", true)}
            >Connect Wallet</Button
          >
        {/if}
      </div>
    {/if}
  </div>
</div>
