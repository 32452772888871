<script>
  import networkList from "./constants/networkList.js";
  import DetailList from "./components/List/DetailList.svelte";
  import { ethers } from "ethers/dist/ethers.esm";
  import { onMount } from "svelte";
  import { toasts, ToastContainer, FlatToast } from "svelte-toasts";
  import HighMonkey from "./contracts/HighMonkey.json";
  import RightComponent from "./shared/Home/RightComponent.svelte";
  import LeftComponent from "./shared/Home/LeftComponent.svelte";
  import {
    isWalletConnected,
    setWalletConnect,
  } from "./helpers/disconnectWallet";

  const defaultNetwork = parseInt(process.env.SVELTE_APP_DEFAULT_NETWORK);
  const contractAddress = process.env.SVELTE_APP_CONTRACT_ADDRESS;

  let balance;
  let hexBalance;

  let ready = false;

  let isOpen = false;

  $: minNumberofToken = parseInt(process.env.SVELTE_APP_MIN_NUMBER_OF_TOKEN);
  $: maxNumberofToken = parseInt(process.env.SVELTE_APP_MAX_NUMBER_OF_TOKEN);

  let transationMintUrl = "";

  let totalBalance;
  let minEtherValue;
  // let minEtherValue = parseFloat(process.env.SVELTE_APP_MIN_ETHER_VALUE);
  let account;

  $: totalBalance = parseFloat(minNumberofToken * minEtherValue).toFixed(4);

  $: metamask = window.ethereum;

  $: web3 = window.ethereum
    ? new ethers.providers.Web3Provider(window.ethereum, "any")
    : null;

  $: isConnected = false;

  $: signer = web3 ? web3.getSigner() : null;

  $: contract = new ethers.Contract(contractAddress, HighMonkey, signer);

  $: isSaleActive = web3 ? showCanMintButton() : false;

  $: web3 ? setAccountChangeHandler() : null;

  $: account, minMaxTokenInit();

  $: account, setEtherValue();

  $: account, showCanMintButton();

  $: contract, setMaxNumberofTokenFromServer();

  const setMaxNumberofTokenFromServer = async () => {
    try {
      if (await contract.publicMintActive()) {
        minNumberofToken = 1;
        maxNumberofToken = Math.round(
          parseFloat(
            ethers.utils.formatEther(await contract.MAX_PUBLIC_MINT())
          ) *
            10 ** 18
        );
      }
    } catch (error) {
      minNumberofToken = 0;
      maxNumberofToken = 0;
    }
  };

  const setEtherValue = async () => {
    if (!account) return;
    minEtherValue = ethers.utils.formatEther(await contract.pricePerToken());
  };

  $: minMaxTokenInit = async () => {
    if (!account) return;
    const availableMintSize = await contract.numberAvailableToMint(account);
    if (!availableMintSize) {
      minNumberofToken = 0;
      maxNumberofToken = 0;
    } else {
      minNumberofToken = 1;
      maxNumberofToken = availableMintSize;
    }
  };

  const refreshBalance = async () => {
    setInterval(async function () {
      if (isConnected) {
        hexBalance = await web3.getBalance(account);
        balance = await ethers.utils.formatEther(hexBalance);
        balance = parseFloat(balance).toFixed(4);
        // TODO: Refresh Min Max Token and Refresh Showing Mint Button or not
        // minMaxTokenInit();
      }
    }, 10000);
  };

  onMount(async () => {
    refreshBalance();
    // setAccountChangeHandler();
    if (isWalletConnected()) {
      try {
        const tempIsConnected = (await web3.listAccounts()).length > 0;
        if (tempIsConnected) {
          await requestBalance(true);
          await minMaxTokenInit();
          isConnected = true;
          setWalletConnect(true);
        } else {
          isConnected = false;
          setWalletConnect(false);
        }
      } catch (error) {
        isConnected = false;
        setWalletConnect(false);
      } finally {
        ready = true;
      }
    } else {
      isConnected = false;
      ready = true;
    }
  });

  const initConnect = async () => {
    // await requestWallet();
    if (window.ethereum) {
      await requestBalance(true);
    } else {
      toasts.error("Please connect from a Web3 enabled browser");
    }
  };

  const handleConnectBtn = (e) => {
    e.detail ? initConnect() : disconnectWallet();
  };

  const disconnectWallet = async () => {
    localStorage.setItem("isConnected", false);
    isConnected = false;
    resetWallet();
    // window.ethereum.emit("disconnect");
    // console.log(ethereum);
    // metamask.emit("disconnect");
    toasts.info("Wallet Disconnected", {
      duration: 10000,
    });
  };

  // const toggleModal = () => {
  //   isOpen = !isOpen;
  // };

  const setAccountChangeHandler = () => {
    web3.on("network", (newNetwork, oldNetwork) => {
      if (oldNetwork) {
        if (newNetwork.chainId != defaultNetwork) {
          requestBalance();
          toasts.error(
            `Please use ${
              networkList.find((network) => network.id === defaultNetwork).name
            } Network`
          );
          // if not rinkeby network
          // changeNetwork(defaultNetwork);
        } else {
          requestBalance();
        }
      }
    });

    metamask.on("accountsChanged", (accounts) => {
      // if (!accounts[0]) {
      //   disconnectWallet();
      // }
      if (!accounts[0]) {
        resetWallet();
      }
      // console.log("changed " + accounts);
    });

    web3.on("disconnect", (error) => {
      resetWallet();
      toasts.info("Wallet Disconnected");
    });
  };

  const requestWallet = async () => {
    try {
      const accountAddress = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      isConnected = true;
      account = accountAddress[0];
      return accountAddress[0];
    } catch (error) {
      toasts.error(error.message);
    }
  };

  const requestBalance = async (shouldCheck) => {
    if (!(await isCorrectNetwork())) {
      // toasts.error(
      //   `Please use ${
      //     networkList.find((network) => network.id === defaultNetwork).name
      //   } Network`
      // );
      if (await changeNetwork(defaultNetwork)) {
        toasts.success("Successfully Connected to Network");
      }
      return;
    }
    if (isWalletConnected() || shouldCheck) {
      try {
        const account = await requestWallet();
        if (account) {
          // const network = await getNetwork();
          // if (network.chainId == defaultNetwork) {
          hexBalance = await web3.getBalance(account);
          balance = await ethers.utils.formatEther(hexBalance);
          balance = parseFloat(balance).toFixed(4);
          isOpen = false;
          isConnected = true;
          // } else {
          //   toasts.error("Please use Rinkeby Network");
          //   if (await changeNetwork(defaultNetwork)) requestBalance();
          // }
          setWalletConnect(true);
        }
      } catch (error) {
        toasts.error(error.message);
      } finally {
        isOpen = false;
      }
    }
  };

  const getNetwork = async () => {
    const network = await web3.getNetwork();
    return network;
  };

  const getPricePerToken = async () => {
    await contract.pricePerToken();
  };

  const showCanMintButton = async () => {
    if (!account) return false;
    try {
      if (await contract.publicMintActive()) return true;
      return await isAddressAvailableToMint(account);
      // if (!(await isAddressAvailableToMint(account))) return false;
      // if (await contract.publicMintActive()) return true;
      // if (await contract.whitelistMintActive()) {
      //   if (await isAddressAvailableToMint(account)) return true;
      //   return;
      // }
      // return false;
      // return await contract.saleIsActive();
    } catch (error) {
      return false;
    }
  };

  const isApprovedForPreSale = async () => {
    if (!isConnected && !account) throw Error();
    if (await contract.publicMintActive()) throw Error();
    if (!(await contract.whitelistMintActive())) throw Error();
    if (!(await isAddressAvailableToMint(account))) throw Error();
    // if (!(await contract.whitelistMintActive())) throw Error();
    return (
      (await isAddressAvailableToMint(account)) &&
      (await contract.numberAvailableToMint(account))
    );
  };

  const isAddressAvailableToMint = async (address) => {
    if (!address) return;
    return await contract.numberAvailableToMint(address);
  };

  const changeNetwork = async (chainId) => {
    // console.log(`request change 0x${chainId}`);
    try {
      await metamask.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${chainId}` }],
      });
      return true;
    } catch (error) {
      toasts.error({
        title: "Network Select Failed",
        description: `Please use ${
          networkList.find((network) => network.id === defaultNetwork).name
        } Network`,
      });
      return;
    }
  };

  const isCorrectNetwork = async () => {
    const network = await getNetwork();
    return network.chainId == defaultNetwork;
  };

  const amountChange = (condition) => {
    switch (condition) {
      case "+":
        if (minNumberofToken === maxNumberofToken) return;
        minNumberofToken += 1;
        break;
      case "-":
        if (minNumberofToken === 1) return;
        if (minNumberofToken < 1) return;
        minNumberofToken -= 1;
        break;
      default:
        break;
    }
  };

  const setMaxNumberofToken = () => {
    minNumberofToken = maxNumberofToken;
  };

  const resetWallet = () => {
    account = null;
    isConnected = false;
    setWalletConnect(false);
    minNumberofToken = 0;
    maxNumberofToken = 0;
    balance = null;
  };

  const getNFTCount = async () => {
    return await contract.totalSupply();
  };

  const getMintable = async () => {
    const account = await requestWallet();
    return await contract.numAvailableToMint(account);
  };

  const canMint = () => {
    return balance > totalBalance;
  };

  const mintNFTLogic = async () => {
    try {
      //   const etherValue = (minNumberofToken * 0.0555).toString();
      const etherValue = totalBalance.toString();
      let overrides = {
        // To convert Ether to Wei:
        value: ethers.utils.parseEther(etherValue), // ether in this case MUST be a string

        // Or you can use Wei directly if you have that:
        // value: someBigNumber
        // value: 1234   // Note that using JavaScript numbers requires they are less than Number.MAX_SAFE_INTEGER
        // value: "1234567890"
        // value: "0x1234"

        // Or, promises are also supported:
        // value: provider.getBalance(addr)
      };

      let minted;
      if (await contract.publicMintActive()) {
        minted = await contract.mint(minNumberofToken, overrides);
      } else {
        minted = await contract.mintWhitelisted(minNumberofToken, overrides);
      }
      transationMintUrl = `${process.env.SVELTE_APP_NETWORK_URL}${minted.hash}`;
      toasts.info("Mint Started");
    } catch (error) {
      toasts.error(error.message);
    }
  };

  const mintNFT = async () => {
    if (isConnected) {
      if (await isCorrectNetwork()) {
        // if (await isPreSaleActive()) {
        if (!canMint()) {
          toasts.error("You do not have enough Balance");
          return;
        }
        mintNFTLogic();
        // }
        // else {
        //   toasts.error("Sale is not Active to Mint");
        // }
      } else {
        toasts.error("Please Connect to a Correct Network");
        changeNetwork(defaultNetwork);
      }
    } else {
      toasts.error("Please Connect to a Wallet");
    }
  };
</script>

<main class="bck-particles">
  <div class="w-screen">
    <div
      class="w-full h-full grid grid-cols-1 lg:grid-cols-2 overflow-x-hidden border-b-2"
    >
      <LeftComponent
        {ready}
        {isConnected}
        {getNFTCount}
        {balance}
        {amountChange}
        {minNumberofToken}
        {totalBalance}
        {mintNFT}
        {transationMintUrl}
        {setMaxNumberofToken}
        {isSaleActive}
        {account}
        {initConnect}
        {showCanMintButton}
        {isApprovedForPreSale}
        {minEtherValue}
        {handleConnectBtn}
      />
      <RightComponent {ready} {isConnected} {handleConnectBtn} />
    </div>
  </div>
  <footer>
    <DetailList {ready} />
  </footer>
</main>

<ToastContainer placement="bottom-right" let:data>
  <FlatToast {data} />
</ToastContainer>
