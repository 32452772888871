<script>
  export let src;
  export let title;
  export let subtitle;
  export let description;
</script>

<div class="inline-flex flex-col lg:flex-row justify-center items-center">
  <div>
    <img class="max-h-28 px-2" {src} alt="icon-img" />
  </div>
  <div
    class="flex flex-col items-center lg:items-start justify-center space-y-1 px-4 max-w-md lg:max-w-full"
  >
    <span class="text-white font-bold text-xl hidden lg:block">{title}</span>
    <span
      class="text-white text-sm font-bold font-syncopate uppercase hidden lg:block"
      >{subtitle}</span
    >

    <span
      class="text-white text-sm font-bold font-syncopate uppercase text-center lg:hidden"
      >{`${title}  ${subtitle}`}</span
    >

    <span class="text-white text-xs text-center lg:text-left"
      >{description}</span
    >
  </div>
</div>
