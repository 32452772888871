<script>
  export const dark = false;
  export let isDefault = false;
  export let isPaddingDefault = false;
  export let isRounded = false;
  export let classNames = "";
</script>

<button
  class={`btn ${classNames}`}
  class:bg-primary-btn={isDefault}
  class:px-12={isPaddingDefault}
  class:rounded-md={isRounded}
  type="button"
  on:click><slot /></button
>
