<script>
  import { fly, fade } from "svelte/transition";
  import DefaultCarousel from "../../components/Carousel/DefaultCarousel.svelte";
  import RightNavbar from "../../components/Navbar/RightNavbar.svelte";
  export let ready;
  export let handleConnectBtn;
  export let isConnected;
</script>

<div class="promotion-container relative block overflow-hidden lg:min-h-screen">
  <RightNavbar {ready} on:walletBtnHandler={handleConnectBtn} {isConnected} />
  <div
    class="w-full flex flex-col items-center justify-center my-4 lg:mt-20 lg:mb-0"
  >
    {#if ready}
      <span
        class="m-auto flex items-center flex-col"
        in:fly={{ y: 150, duration: 800 }}
      >
        <DefaultCarousel />
        <div
          class="text-white text-center text-sm max-w-md my-8 z-20 px-4 lg:px-0"
        >
          Each High Monk is unique and early supporters are able to mint up to 3
          NFTs per wallet at a discounted price.
        </div>
      </span>
    {/if}
  </div>
  {#if ready}
    <img
      in:fly={{ y: 50, duration: 800, delay: 50 }}
      out:fade
      class="bottom-0 right-0 hidden lg:block"
      src="./images/Monks-Lineup.png"
      alt="monk-temple"
    />
  {/if}
</div>
