<script>
  import { fade } from "svelte/transition";
  import { fly } from "svelte/transition";
  import IconCard from "../Cards/IconCard.svelte";
  export let ready;

  const footerCardData = [
    {
      id: 1,
      src: "./images/mint-page-images_25.png",
      title: "11,111 NFT",
      subtitle: "Memberships Cards",
      description:
        "A HighMonks NFT Doubles As A Membership Card To The Stoners Temple",
    },
    {
      id: 2,
      src: "./images/mint-page-images_27.png",
      title: "3 NFT",
      subtitle: "Per Wallet",
      description:
        "A HighMonks NFT Doubles As A Membership Card To The Stoners Temple",
    },
    {
      id: 3,
      src: "./images/mint-page-images_29.png",
      title: "222",
      subtitle: "Traits and Expressions",
      description:
        "Each HighMonks is Unique And Programmatically Generated From 222 Possible Trails",
    },
  ];
</script>

<div
  class="grid grid-cols-1 lg:grid-cols-3 gap-4 p-12 lg:bg-gradient-to-r from-custom-gray to-custom-black space-y-4 lg:space-y-0"
>
  {#if ready}
    {#each footerCardData as item, i}
      <span
        class="lg:first:border-r-2 lg:last:border-l-2 border-x-white flex justify-center"
        in:fly={{ y: 150, duration: 800 }}
      >
        <IconCard
          src={item.src}
          title={item.title}
          subtitle={item.subtitle}
          description={item.description}
        />
      </span>
    {/each}
  {/if}
</div>
{#if ready}
  <img
    in:fly={{ y: 50, duration: 800, delay: 50 }}
    out:fade
    class="block lg:hidden"
    src="./images/Monks-Lineup.png"
    alt="monk-temple"
  />
{/if}
