const networkList = [
  {
    id: 1,
    name: "Ethereum Mainnet",
  },
  {
    id: 4,
    name: "Rinkeby",
  },
];

export default networkList;
