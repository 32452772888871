<script>
  import { createEventDispatcher } from "svelte";

  import shortcutAccount from "../../helpers/shortcutAccount";
  import Button from "../Button.svelte";

  const dispatch = createEventDispatcher();

  export let ready;
  export let isConnected;
  export let isSaleActive;
  export let account;
</script>

<div
  class="w-full flex flex-col items-center justify-center lg:block border-b md:border-b-0 border-b-white mb-4 md:mb-0 pb-6 md:pb-0"
>
  <img
    class="w-52 m-8 mx-14"
    src="./images/high-monks-logo.png"
    alt=""
    srcset=""
  />
  {#if ready}
    {#if isSaleActive && isConnected}
      <div
        class="flex md:hidden bg-primary-btn text-white  rounded-sm px-12 items-center justify-center p-4"
      >
        <img
          class="max-h-6 mx-4 mr-6"
          src="./images/tick-mint.png"
          alt="pre-sale"
        />
        <span> {shortcutAccount(account)}</span>
      </div>
    {:else}
      <Button
        classNames="py-2 text-sm px-6 block lg:hidden"
        isDefault={true}
        isRounded={true}
        on:click={() => {
          if (
            typeof window.ethereum !== "undefined" ||
            typeof window.web3 !== "undefined"
          ) {
            dispatch("walletBtnHandler", true);
          } else {
            window.open(
              // `https://metamask.app.link/dapp/${process.env.SVELTE_APP_MAIN_DOMAIN}`
              `https://metamask.app.link/dapp/${window.location.host}`
            );
          }
        }}>Connect to Wallet</Button
      >
    {/if}
  {/if}
</div>
