const shortcutAccount = (account) => {
  if (account) {
    const length = account.length;
    return `${account.slice(0, 3)}.....${account.slice(length - 3, length)}`;
  } else {
    return ``;
  }
};

export default shortcutAccount;
