<script>
  import { fade, scale } from "svelte/transition";
  import { fly } from "svelte/transition";
  import Button from "../../components/Button.svelte";
  import LeftNavbar from "../../components/Navbar/LeftNavbar.svelte";
  import shortcutAccount from "../../helpers/shortcutAccount";

  export let ready;
  export let isConnected;
  export let getNFTCount;
  export let balance;
  export let amountChange;
  export let minNumberofToken;
  export let totalBalance;
  export let mintNFT;
  export let transationMintUrl;
  export let setMaxNumberofToken;
  export let isSaleActive;
  export let account;
  export let initConnect;
  export let showCanMintButton;
  // export let isAddressAvailableToMint;
  export let isApprovedForPreSale;
  export let minEtherValue;
  export let handleConnectBtn;
</script>

<div class="md:border-r-2 border-r-white overflow-hidden min-h-screen">
  <LeftNavbar
    {ready}
    {isConnected}
    {isSaleActive}
    {account}
    on:walletBtnHandler={initConnect}
  />
  <div class="flex flex-col items-center justify-center">
    {#if ready}
      <div
        class="inline-block max-w-sm px-2 md:px-0 py-10 w-full md:w-1/2 lg:w-full lg:px-14 xl:w-full md:max-w-xl space-y-8"
        in:fly={{ y: 150, duration: 800 }}
        out:fade
      >
        <div
          class="flex flex-col justify-center items-center md:items-start space-y-4"
        >
          <span class="text-white font-syncopate font-bold uppercase text-3xl"
            >Public Mint</span
          >
          <!-- <span
            class="text-gray-200 font-syncopate uppercase font-thin text-lg"
          >
            {#if isConnected}
              {#await getNFTCount()}
                -
              {:then value}
                {value}
              {/await}
            {:else}
              -
            {/if}
            NFT's Minted</span
          > -->
        </div>
        <div class="flex flex-row justify-between">
          <span class="text-white">Balance</span>
          <span class="text-white">
            {#if balance}
              {balance}
              <span>ETH</span>
            {/if}
          </span>
        </div>
        <div class="flex flex-row items-center justify-between">
          <span class="text-white">Amount</span>
          <span class="flex flex-row items-center space-x-4">
            <button
              class="text-2xl font-bold text-gray-400"
              on:click={() => amountChange("-")}>-</button
            >
            <span class="text-white text-3xl font-bold">
              <!-- {#if minNumberofToken} -->
              <span in:scale={{ duration: 300 }}>
                {minNumberofToken}
              </span>
              <!-- {/if} -->
            </span>
            <button
              class="text-2xl font-bold text-gray-400"
              on:click={() => amountChange("+")}>+</button
            >
          </span>
          <span>
            <button
              class="p-2 bg-primary-btn text-white rounded-md font-bold text-base"
              on:click={setMaxNumberofToken}>Max</button
            >
          </span>
        </div>
        <div class="flex flex-row justify-between">
          <span class="text-white">Total</span>
          <span class="text-white"
            >{#if totalBalance}
              {totalBalance} ETH
            {/if}</span
          >
        </div>
        <div class="flex flex-row justify-between">
          <span class="text-white">Price Per Token</span>
          <span class="text-white">
            {#if minEtherValue}
              {minEtherValue} ETH
            {/if}
          </span>
        </div>
        <div
          class="flex flex-col md:flex-row items-center justify-between mt-12 space-y-8 md:space-y-0"
        >
          <div
            class="w-full flex md:block flex-col items-center md:min-w-min space-y-8"
          >
            {#if ready}
              {#if isConnected}
                {#await showCanMintButton()}
                  <Button
                    classNames="text-lg py-2 rounded-sm md:rounded-md w-64 cursor-not-allowed bg-opacity-70 bg-gray-600"
                    isDefault={true}
                    isPaddingDefault={true}>Loading</Button
                  >
                {:then showCanMintButtonActive}
                  <!-- {#await isAddressAvailableToMint(account)}
                  <span />
                {:then isAddressAvailableToMint} -->
                  <!-- isAddressAvailableToMint -->

                  {#if showCanMintButtonActive}
                    <Button
                      classNames="text-lg py-2 rounded-sm md:rounded-md w-64"
                      isDefault={true}
                      isPaddingDefault={true}
                      on:click={mintNFT}>Mint Now</Button
                    >
                  {:else}
                    <Button
                      classNames="text-lg py-2 rounded-sm md:rounded-md w-64 cursor-not-allowed bg-opacity-70 bg-gray-600"
                      isDefault={true}
                      isPaddingDefault={true}>Unavailable</Button
                    >
                  {/if}
                  <!-- {/await} -->
                {/await}
              {:else}
                <Button
                  on:click={handleConnectBtn}
                  classNames="text-lg py-2 rounded-sm md:rounded-md w-64"
                  isDefault={true}
                  isPaddingDefault={true}>Connect Wallet</Button
                >
              {/if}
              <!-- <Button
              classNames="text-lg py-2 rounded-sm md:rounded-md w-64"
              isDefault={true}
              isPaddingDefault={true}
              on:click={mintNFT}>Mint Now</Button
            > -->
              {#if isConnected}
                {#await isApprovedForPreSale()}
                  <span />
                {:then value}
                  {#if value}
                    <div class="flex items-center">
                      <img
                        class="h-4"
                        src="./images/tick-mint.png"
                        alt="tick"
                      />
                      <span class="text-xs text-white">
                        {shortcutAccount(account)} is approved for presale
                      </span>
                    </div>
                  {:else}
                    <div class="flex items-center">
                      <img
                        class="h-4 mr-2"
                        src="./images/cross.png"
                        alt="cross"
                      />
                      <span class="text-xs text-white">
                        {shortcutAccount(account)} is not approved for presale
                      </span>
                    </div>
                  {/if}
                {:catch error}
                  <span />
                {/await}
              {/if}
            {/if}
          </div>
          <div>
            <img
              class="max-h-28 md:max-h-32"
              src="./images/pre-sale-ming-high.png"
              alt="pre-sale"
            />
          </div>
        </div>
        <div class="flex items-start">
          {#if transationMintUrl}
            <Button
              classNames="text-center flex justify-center px-4 py-2"
              isRounded={true}
              isDefault={true}
              on:click={() => window.open(transationMintUrl)}>See Hash</Button
            >
          {/if}
        </div>
      </div>
    {/if}
  </div>
</div>
